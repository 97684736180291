/*!────────────────────────────────────────
 * _top_mobile_gnav.scss
 * ──────────────────────────────────────── */
//後から追加されたナビメニュー
.mv_nav {
  display: block;
  padding: 0px 10px;
  margin: 0 auto 20px;
  @include mq(sp) {
    max-width: 330px;
  }
  .nav_list {
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 1.414px 1.414px 2px 0px rgba(21, 0, 10, 0.2);
    list-style: none;
  }

  li {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
} //mv_nav
//共通ボタン
/* ===== btn ===== */
.cmn-btn.type01 {
  display: table;
  color: #000;
  width: 100%;
  height: 55px;
  position: relative;
  font-size: 1.5rem;
  background: #f2f6fa;
  border: 1px solid #dee4e8;
  border-radius: 4px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 38px;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 1.3;
  transition: 0.3s;

  span {
    display: table-cell;
    vertical-align: middle;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    width: 24px;
    height: 100%;
    background: #4488c7;
    top: 0;
    right: 0;
  }

  &:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 7px;
    border-color: transparent transparent transparent #fff;
    right: 8px;
    top: 50%;
    margin-top: -4px;
  }
}
