/*!────────────────────────────────────────
 * _access_category.scss
 * ──────────────────────────────────────── */

@media screen and (max-width: 767px) {
  #access #tabs ul.tab5 {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 10px;
  }
}
@media screen and (max-width: 375px) {
  #access {
    #tabs ul.tab5,
    table#parking_price {
      font-size: 10 / 375 * 100vw;
    }
    .parking_price_wrp {
      margin-left: 0;
      margin-right: 0;
    }
    table#parking_price {
      width: 100%;
      td {
        padding: 5px 0;
      }
    }
  }
}
