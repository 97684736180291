/*!────────────────────────────────────────
 * _top_page.scss
 * ──────────────────────────────────────── */
body#top {
  #mainImg {
    width: 100%;
    height: 0;
    padding-top: 231/950 * 100%;
  }
  #main_area {
    width: 100%;
    #main {
      width: 98%;
      margin: 0 auto;
      background-size: cover;
      background-position: right 0.75px bottom;
      padding-bottom: 10 / 955 * 100%;
    }
  }
  #category {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    p {
      position: relative;
      width: 100%;
      padding-top: 233 / 952 * 100%;
    }
  }
  .ttl_h2 {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    // margin: 0 auto;
  }
  #main {
    .content {
      width: 310 / 955 * 100%;
      margin-right: 12 / 955 * 100%;
      // &:nth-child(2) {
      //   .content_ttl { //実機で確認のこと
      //     width: 99.87%;
      //     transform: translateX(-0.5px);
      //     margin: 0 auto;
      //   }
      // }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  ul.pickup {
    display: flex;
    justify-content: space-around;
    align-items: center;
    li {
      float: none;
      width: 302 / 947 * 100%;
      margin: 0;
    }
  }

  #news {
    background: none;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    .content {
      float: none;
      width: 280 / 955 * 100%;
      // margin-right: 12 / 955 * 100%;
      margin: 0;
      border: 1px solid #f3f3f3;
      &.end {
        width: 280 / 955 * 100%;
      }
    }
  }
  #side {
    width: 710/750 * 100%;
  }
  .container {
    .top {
      background: transparent;
    }
  }
}
@media screen and (max-width: 480px) {
  body#top {
    #main_area {
      #main {
        background: none;
        .content {
          float: none;
          width: 100%;
          max-width: 310px;
          margin: 0 auto 20px;
          background-color: #f3f3f3;
          border: 2px solid #c8c8c8;
          border-top: none;
          // &:nth-child(2) {
          //   .content_ttl { //実機で確認のこと
          //     width: 99.87%;
          //     transform: translateX(-0.5px);
          //     margin: 0 auto;
          //   }
          // }
          &:last-child {
            margin-bottom: 0;
          }
        }
        p.title.mobile {
          width: 100%;
          margin-bottom: 10px;
          font-size: 1.6rem;
          color: #4488c7;
          line-height: 1.6;
          border-top: 1px solid #4488c7;
          background-color: #fff;
          padding: 0.3em 0.5em;
        }
      } //main_area main
    } //main_area
    #main {
      .content_ttl.mobile {
        font-size: 1.8rem;
        font-weight: bold;
        border-top: 2px solid;
        padding: 0.5em;
        background-color: #fff;
        &.kenkyu {
          color: #6ebbe7;
          border-color: #6ebbe7;
        }
        &.shinryou {
          color: #e968a1;
          border-color: #e968a1;
        }
        &.kyouiku {
          color: #90c320;
          border-color: #90c320;
        }
      } //.content_ttl.mobile
    } //#main
    .ttl_h2 {
      max-width: 310px;
      &:nth-child(2) {
        text-align: center;
        margin-top: 80px;
      }
    }
    ul.pickup {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        width: 100%;
        max-width: 302px;
        margin: 0 auto 10px;
      }
    } //ul.pickup
    #news {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 98%;
      margin: 0 auto;
      .content {
        width: 100%;
        margin-bottom: 20px;
        &.end {
          width: 100%;
          margin: 0;
        }
      }
    }
  } //body#top
} //480以下
@media screen and (max-width: 375px) {
  body#top {
    #main_area {
      #main {
      }
    }
    #main {
    }
  }
}
