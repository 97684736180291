@charset "UTF-8";
/*!────────────────────────────────────────
 * mobile.scss
 * for [がん研究会](https://www.jfcr.or.jp/)
 * 日本語用
 * ──────────────────────────────────────── */
/*!────────────────────────────────────────
 * _common_settings.scss
 * ──────────────────────────────────────── */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap");
html {
  font-size: 62.5%;
}

body {
  font-size: 83.33333% !important;
  border-top: none;
  font-family: "Noto Sans Japanese", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W6", "メイリオ", "Meiryo", "MS PGothic", sans-serif;
}

body.index {
  margin: 0;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

#bread > p {
  display: none;
}

#contents {
  width: 100%;
}

#main_area {
  width: 100%;
  float: none;
}

#main {
  width: 94.66667%;
  margin: 0 auto;
}

.totop {
  display: none;
}

#dataTable a img {
  display: none;
}

.wh01 th {
  width: 21%;
}

@media screen and (max-width: 767px) {
  figure.right {
    margin: 12px auto !important;
    text-align: center;
    float: none;
  }
}

/*------------------------------------------
  parts
------------------------------------------*/
/* ===== flexbox ===== */
.flexbox {
  display: flex;
  flex-wrap: wrap;
}

/* p.title */
p.title img,
#main_area h1 img,
#main p.lead img {
  visibility: hidden;
  opacity: 0;
}

p.title.mobile {
  font-size: 1.6rem;
  font-weight: bold;
  color: #4488c7;
  line-height: 1.6;
  border-top: 1px solid #4488c7;
  background-color: #fff;
  padding: 0.3em 0.5em;
  box-shadow: 0px 1px 3px #ccc;
}

p.title.mobile.oshirase a {
  display: block;
  color: #4488c7;
  position: relative;
}

p.title.mobile.oshirase a::before {
  content: "";
  position: absolute;
  left: auto;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  overflow: hidden;
  background: #4488c7;
}

p.title.mobile.oshirase a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 5px;
  border-color: transparent transparent transparent #fff;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}

/* p.lead */
#main p.lead.mobile {
  font-size: 1.6rem;
  font-family: "Noto Serif JP", serif;
}

/* h1.mobile & outline */
#main_area h1.mobile {
  line-height: 1.6;
  font-size: 1.6rem;
  border-left: 3px solid #4488c7;
  padding: 0.5em 0.3em;
  width: 94.66667%;
  margin: 10px auto;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 0px 3px #ccc;
}

#main_area h2 {
  font-size: 1.4rem;
  margin: 20px auto;
}

/* 年号リスト */
p.page_link {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

/* Top 休部中を最後尾に */
body#top #main .container #dept #dep14 {
  order: 13;
}

body#top #main .container #dept #dep13 {
  order: 14;
}

#link01 + p::after {
  content: "";
  display: block;
  clear: both;
}

@media screen and (max-width: 480px) {
  img.right {
    margin: 0 auto 20px !important;
    text-align: center;
    float: none;
    display: block;
  }
  #main ul.photo {
    float: none;
  }
  #main ul.photo li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    float: none;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 375px) {
  /* オリジナルHPへ改行 */
  .icon_img.small {
    display: block;
    margin-top: 1em;
  }
  /* researcherコンテンツ幅100% */
  .res_left {
    float: none;
    width: 100%;
  }
  /* SPのみ左寄せ */
  #main p.t_right {
    text-align: left;
  }
  #main .index p a {
    width: 100%;
    min-height: 68px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

/*!────────────────────────────────────────
 * _mobile_header.scss
 * ──────────────────────────────────────── */
/*------------------------------------------
  header
------------------------------------------*/
#header_area,
#gnavi {
  width: 0;
  height: 0;
  opacity: 0;
  font-size: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
}

#mobile_header {
  display: block;
}

.site-header {
  letter-spacing: 0.08em;
  position: relative;
}

.header-contents {
  background: #fff;
  padding: 16px 20px;
  align-items: center;
}

.groval-nav {
  background: #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
}

.groval-nav ul,
.groval-nav ol {
  list-style: none;
}

.groval-nav-fix {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.gnav-list {
  text-align: center;
  max-width: 1600px;
  margin: auto;
}

.gnav-list img {
  display: none;
}

.gnav-item {
  width: 20%;
  position: relative;
}

.gnav-item:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #d0d5d9;
  position: absolute;
  top: 0;
  right: 1px;
}

.gnav-item:last-child:before {
  content: none;
}

.gnav-item:last-child .gnav-link {
  border-right: none;
}

.gnav-item.active {
  background: #d3e8c1;
}

.gnav-item.active:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 11px 12px 11px;
  border-color: transparent transparent #3c5a8c transparent;
  position: absolute;
  bottom: -2px;
  left: 50%;
  margin-left: -11px;
  z-index: 3;
}

.gnav-link {
  font-size: 4.8vw;
  display: block;
  border-right: 1px solid #fff;
  padding: 10px 0;
  font-weight: 600;
}

.gnav-dropdown {
  position: absolute;
  width: 100%;
}

.dropdown {
  display: none;
  position: absolute;
  width: 100%;
  border-top: 2px solid #d0d5d9;
  background: #b7d049;
  z-index: 2;
}

.dropdown ul {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  justify-content: flex-start;
}

.dropdown li {
  width: 33.333%;
  margin-top: 10px;
  padding: 0 5px;
  box-sizing: border-box;
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 100% 100% 100%;
  -ms-grid-rows: 100% 100% 100%;
}

.dropdown li:nth-child(1), .dropdown li:nth-child(2), .dropdown li:nth-child(3) {
  margin-top: 0;
}

.dropdown li:nth-child(3n) {
  margin-right: 0;
}

.dropdown a {
  display: block;
  padding: 1em;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding-right: 26px;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.dropdown a:before {
  content: "";
  width: 22px;
  background: #fff;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.dropdown a:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #1e3c6e;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 7px;
}

.dropdown a:hover {
  color: #00b496;
}

.gnav-list-sp01 {
  padding-bottom: 0;
}

.gnav-list-sp01 ul {
  justify-content: space-between;
  padding: 0 0 1.5rem 0;
  margin: 0 0 0 0;
  list-style: none;
}

.gnav-list-sp01 li {
  width: 49%;
  margin-bottom: 2%;
}

.gnav-list-sp01 .gnav-item {
  width: 100%;
  margin-bottom: 2%;
}

.gnav-list-sp01 a {
  display: block;
  background: #fff;
  font-size: 4vw;
  padding: 1em;
  border: 1px solid #fff;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  padding-right: 26px;
  font-weight: 600;
}

.gnav-list-sp01 a:before {
  content: "";
  width: 22px;
  background: #fff;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.gnav-list-sp01 a:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #1e3c6e;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 7px;
}

.gnav-list-sp01 img {
  display: none;
}

.gnav-list-sp02 {
  padding-bottom: 10px;
}

.gnav-list-sp02 ul {
  padding: 0 0 15px 0;
  margin: 0 0 0 0;
}

.gnav-list-sp02 li {
  width: 100%;
  margin-bottom: 2%;
}

.gnav-list-sp02 a {
  display: block;
  background: #bbbbbb;
  font-size: 4vw;
  padding: 1em;
  border: 1px solid #bbbbbb;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff !important;
  font-weight: 600;
}

.gnav-list-sp02 img {
  display: none;
}

.site-header {
  padding-bottom: 10px;
}

.header-contents {
  padding: 10px;
  height: 60px;
}

.sitelogo {
  width: 40vw;
}

.header-menu {
  display: none;
}

.gnav-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 16vw;
  max-width: 60px;
  height: 16vw;
  max-height: 60px;
  top: 0;
  right: 0;
  background: #4488c7;
  z-index: 30;
  position: fixed;
  z-index: 3;
}

.gnav-trigger div {
  width: 8vw;
  max-width: 30px;
  height: 6.93333vw;
  max-height: 26px;
  position: absolute;
  top: 28.33333%;
  left: 25%;
}

.gnav-trigger span {
  transition: 0.2s;
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
}

.gnav-trigger span:nth-of-type(1) {
  top: 0;
}

.gnav-trigger span:nth-of-type(2) {
  top: 50%;
  margin-top: -1px;
}

.gnav-trigger span:nth-of-type(3) {
  bottom: 0;
}

.groval-nav {
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto !important;
  z-index: 25;
  background: #ececec;
  border-bottom: none;
  padding: 10px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

.gnav-logo {
  margin: 0 0 10px 0;
  width: 40vw;
  height: 40px;
}

.gnav-logo a img {
  margin: 11px 0;
}

.gnav-close {
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  color: #fff;
  padding: 15px 0;
  font-size: 4.26667vw;
  background: #4488c7 url(../images/common/ico-close.png) no-repeat left 20px center;
  background-size: 23px;
}

.gnav-list {
  padding: 15px 0;
}

.gnav-item {
  width: 100%;
  margin-bottom: 5px;
}

.gnav-item:last-child {
  margin-bottom: 0;
}

.gnav-item:before, .gnav-item.active:after {
  content: none;
}

.gnav-link {
  text-align: left;
  border: 1px solid #fff !important;
  background: #fff;
  color: #4488c7;
  border-radius: 4px;
  font-size: 4vw;
  padding-left: 20px;
  position: relative;
}

.gnav-link:before {
  content: "";
  width: 22px;
  background: #fff;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.gnav-link:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #1e3c6e;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 7px;
}

.gnav-dropdown {
  display: none;
}

a:link {
  color: #1e3c6e;
  text-decoration: none;
}

h1 {
  margin: 6px 0 6px 0;
}

@media only screen and (min-width: 768px) and (max-width: 953px) {
  .sitelogo {
    width: calc(100% - 60px - 10px);
  }
  .header-menu {
    width: 70%;
  }
  .header-menu .menu-list {
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 6px;
  }
  .header-menu .guide-list {
    width: 100%;
    justify-content: flex-end;
  }
  .gnav-link {
    font-size: 3.73333vw;
  }
  .dropdown {
    font-size: 3.46667vw;
  }
}

@media only screen and (max-width: 460px) {
  .sitelogo,
  .gnav-logo {
    width: 60vw;
  }
}

@media only screen and (min-width: 376px) and (max-width: 953px) {
  .gnav-link {
    font-size: 1.5rem !important;
  }
  .gnav-list-sp01 a {
    font-size: 1.5rem !important;
  }
  .gnav-list-sp02 a {
    font-size: 1.5rem !important;
  }
  .gnav-close {
    font-size: 1.6rem !important;
  }
}

/*!────────────────────────────────────────
 * _mobile_footer.scss
 * ──────────────────────────────────────── */
/*------------------------------------------
  footer
------------------------------------------*/
@media only screen and (min-width: 376px) and (max-width: 953px) {
  .cmn-btn.type01 {
    font-size: 1.4rem !important;
  }
  .footer-about .about-title {
    font-size: 1.7rem !important;
  }
  .footer-about .about-address {
    font-size: 1.3rem !important;
  }
  .footer-copyright {
    font-size: 1rem !important;
  }
  .footer-link .link-list {
    font-size: 1.3rem !important;
  }
}

@media only screen and (max-width: 953px) {
  #footer_area {
    display: none;
  }
  #mobile_footer {
    display: block;
    background-color: white;
    background-image: none;
  }
  #mobile_footer img {
    display: none;
  }
  #mobile_footer ol,
  #mobile_footer ul {
    list-style: none;
  }
  .site-footer {
    padding-bottom: 0px;
  }
  .footer-facility .facility-list {
    width: 100%;
    display: block;
  }
  .footer-top {
    padding: 10px 5px 6px;
  }
  .footer-top .cmn-inner {
    padding: 0;
  }
  .footer-facility {
    padding-top: 10px;
  }
  .footer-facility .facility-list {
    width: 100%;
    display: block;
  }
  .footer-facility .facility-list ul {
    margin: 0;
  }
  .footer-facility .facility-list li {
    width: 50%;
    padding: 0 2px;
    margin-bottom: 4px;
    box-sizing: border-box;
  }
  .footer-facility .facility-list li.thisSite {
    display: none;
  }
  .footer-facility .facility-list .flexbox100 {
    width: 100%;
  }
  .cmn-btn.type01:before {
    width: 24px;
    height: 100%;
    background: #4488c7;
    top: 0;
    right: 0;
  }
  .cmn-btn.type01 {
    display: table;
    width: 100%;
    height: 55px;
    position: relative;
    font-size: 3.73333vw;
    background: #f2f6fa;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-weight: 600;
    padding-left: 15px;
    padding-right: 38px;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 1.3;
    transition: 0.3s;
  }
  .cmn-btn.type01:before,
  .cmn-btn.type01:after {
    content: "";
    position: absolute;
  }
  .cmn-btn.type01 span {
    display: table-cell;
    vertical-align: middle;
  }
  .cmn-btn.type01:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 7px;
    border-color: transparent transparent transparent #fff;
    right: 8px;
    top: 50%;
    margin-top: -4px;
  }
  .footer-bottom {
    padding: 0;
    background: #ffffff;
    color: #656565;
  }
  .footer-bottom .cmn-inner {
    padding: 0;
  }
  .footer-bottom a {
    color: #656565;
    background: #eeeeee;
  }
  .cmn-inner.type01 {
    max-width: 1200px;
  }
  .cmn-inner {
    margin: auto;
  }
  .footer-about {
    padding: 25px 0;
    text-align: center;
    border-bottom: none;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    width: 100%;
    margin-bottom: 0;
    align-items: center;
    justify-content: flex-start;
    background: #eeeeee;
  }
  .footer-about .about-title {
    font-weight: bold;
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 4.53333vw;
  }
  .footer-about .about-address {
    margin: 0;
    padding-left: 0;
    font-size: 3.46667vw;
    width: 100%;
    font-weight: 600;
  }
  .footer-link {
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    width: 100%;
    align-items: center;
  }
  .footer-link .copyright {
    display: none;
  }
  .footer-link .link-list li {
    width: 50%;
    margin-right: 0;
    display: table;
    height: 65px;
    text-align: center;
    border-bottom: 1px solid #ffffff;
  }
  .footer-link .link-list li:nth-child(odd) a {
    border-right: 1px solid #ffffff;
  }
  .footer-link .link-list li:nth-child(even) a {
    border-left: 1px solid #ffffff;
  }
  .footer-link .link-list a {
    display: table-cell;
    vertical-align: middle;
    padding: 0;
    border-bottom: 1px solid #ffffff;
  }
  .footer-link .link-list a:before {
    content: none;
  }
  .footer-copyright {
    text-align: center;
    width: 100%;
    font-size: 2.66667vw;
    font-weight: 600;
    -webkit-order: 3;
    -ms-order: 3;
    order: 3;
    margin: 0;
    background: #eeeeee;
    padding: 0 0 25px;
  }
  .footer-link .link-list {
    width: 100%;
    font-size: 3.46667vw;
    margin: 0;
    font-weight: 600;
  }
  .btn-pagetop {
    bottom: 20px;
    position: fixed;
    right: 0;
    z-index: 20;
  }
  .btn-pagetop a {
    width: 40px;
    height: 40px;
    display: block;
    background: #4488c7;
  }
  .btn-pagetop a:after {
    content: "";
    display: block;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -4px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    margin-left: -5px;
  }
}

/*!────────────────────────────────────────
 * _mobile_side.scss
 * ──────────────────────────────────────── */
#side {
  width: 94.66667%;
  margin: 20px auto;
  float: none;
}

#banner {
  font-size: 1.5rem;
}

#banner li.mobile {
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid #4488c7;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #ccc;
  margin-bottom: 10px;
}

#banner li.mobile a {
  display: block;
  padding: 1em 0.75em;
  color: #4488c7;
  position: relative;
}

#banner li.mobile a::before {
  content: "";
  position: absolute;
  left: auto;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  overflow: hidden;
  background: #4488c7;
}

#banner li.mobile a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 5px;
  border-color: transparent transparent transparent #fff;
  right: 19px;
  top: 50%;
  transform: translateY(-50%);
}

#banner li.mobile:last-child {
  margin-bottom: 0;
}

#banner .left,
#banner .right {
  float: none;
}

#subnavi {
  background: transparent;
  font-size: 1.5rem;
}

#subnavi a {
  color: #4488c7;
}

#subnavi > div {
  border-radius: 5px;
  box-shadow: 0px 0px 5px #ccc;
  background: transparent;
  overflow: hidden;
}

#subnavi > div ul {
  background: transparent;
  padding: 0;
}

#subnavi > div ul ul {
  background-color: transparent;
}

#subnavi p.mobile {
  padding: 0;
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid #4488c7;
  margin-bottom: 10px;
  color: #4488c7;
  padding: 1em 0 1em 0.75em;
}

#subnavi p.mobile a {
  display: block;
  position: relative;
}

#subnavi p.mobile a::before {
  content: "";
  position: absolute;
  left: auto;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  overflow: hidden;
  background: #4488c7;
}

#subnavi p.mobile a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 5px;
  border-color: transparent transparent transparent #fff;
  right: 19px;
  top: 50%;
  transform: translateY(-50%);
}

#subnavi li.mobile {
  border-bottom: 1px solid #ddd;
}

#subnavi li.mobile > a {
  display: block;
  font-size: 93.33333%;
  padding: 1em 0.75em;
  color: #1c3e6e;
  position: relative;
}

#subnavi li.mobile > a::before {
  content: "";
  position: absolute;
  left: auto;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  overflow: hidden;
  background: #1c3e6e;
}

#subnavi li.mobile > a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 5px;
  border-color: transparent transparent transparent #fff;
  right: 19px;
  top: 50%;
  transform: translateY(-50%);
}

#subnavi li.mobile > ul {
  border-top: 1px solid #ddd;
}

#subnavi li.mobile > ul li {
  font-size: 86.66667%;
  border-bottom: 1px solid #ddd;
}

#subnavi li.mobile > ul li a {
  display: block;
  background-image: none;
  background-color: #fff;
  padding: 0.7em 0.5em 0.7em 3em;
  position: relative;
}

#subnavi li.mobile > ul li a::before {
  content: "";
  position: absolute;
  left: auto;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  overflow: hidden;
  background: #4488c7;
}

#subnavi li.mobile > ul li a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 5px;
  border-color: transparent transparent transparent #fff;
  right: 19px;
  top: 50%;
  transform: translateY(-50%);
}

#subnavi li.mobile > ul li a.on {
  background-image: none;
  background-color: #ddd;
}

@media screen and (max-width: 375px) {
  #banner {
    font-size: 4vw;
  }
  #subnavi {
    font-size: 4vw;
  }
}

/*!────────────────────────────────────────
 * _top_page.scss
 * ──────────────────────────────────────── */
body#top #mainImg {
  width: 100%;
  height: 0;
  padding-top: 24.31579%;
}

body#top #main_area {
  width: 100%;
}

body#top #main_area #main {
  width: 98%;
  margin: 0 auto;
  background-size: cover;
  background-position: right 0.75px bottom;
  padding-bottom: 1.04712%;
}

body#top #category {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

body#top #category p {
  position: relative;
  width: 100%;
  padding-top: 24.47479%;
}

body#top .ttl_h2 {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

body#top #main .content {
  width: 32.46073%;
  margin-right: 1.25654%;
}

body#top #main .content:last-child {
  margin-right: 0;
}

body#top ul.pickup {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

body#top ul.pickup li {
  float: none;
  width: 31.89018%;
  margin: 0;
}

body#top #news {
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

body#top #news .content {
  float: none;
  width: 29.31937%;
  margin: 0;
  border: 1px solid #f3f3f3;
}

body#top #news .content.end {
  width: 29.31937%;
}

body#top #side {
  width: 94.66667%;
}

body#top .container .top {
  background: transparent;
}

@media screen and (max-width: 480px) {
  body#top #main_area #main {
    background: none;
  }
  body#top #main_area #main .content {
    float: none;
    width: 100%;
    max-width: 310px;
    margin: 0 auto 20px;
    background-color: #f3f3f3;
    border: 2px solid #c8c8c8;
    border-top: none;
  }
  body#top #main_area #main .content:last-child {
    margin-bottom: 0;
  }
  body#top #main_area #main p.title.mobile {
    width: 100%;
    margin-bottom: 10px;
    font-size: 1.6rem;
    color: #4488c7;
    line-height: 1.6;
    border-top: 1px solid #4488c7;
    background-color: #fff;
    padding: 0.3em 0.5em;
  }
  body#top #main .content_ttl.mobile {
    font-size: 1.8rem;
    font-weight: bold;
    border-top: 2px solid;
    padding: 0.5em;
    background-color: #fff;
  }
  body#top #main .content_ttl.mobile.kenkyu {
    color: #6ebbe7;
    border-color: #6ebbe7;
  }
  body#top #main .content_ttl.mobile.shinryou {
    color: #e968a1;
    border-color: #e968a1;
  }
  body#top #main .content_ttl.mobile.kyouiku {
    color: #90c320;
    border-color: #90c320;
  }
  body#top .ttl_h2 {
    max-width: 310px;
  }
  body#top .ttl_h2:nth-child(2) {
    text-align: center;
    margin-top: 80px;
  }
  body#top ul.pickup {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  body#top ul.pickup li {
    width: 100%;
    max-width: 302px;
    margin: 0 auto 10px;
  }
  body#top #news {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98%;
    margin: 0 auto;
  }
  body#top #news .content {
    width: 100%;
    margin-bottom: 20px;
  }
  body#top #news .content.end {
    width: 100%;
    margin: 0;
  }
}

/*!────────────────────────────────────────
 * _top_mobile_gnav.scss
 * ──────────────────────────────────────── */
.mv_nav {
  display: block;
  padding: 0px 10px;
  margin: 0 auto 20px;
}

@media screen and (max-width: 480px) {
  .mv_nav {
    max-width: 330px;
  }
}

.mv_nav .nav_list {
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 1.414px 1.414px 2px 0px rgba(21, 0, 10, 0.2);
  list-style: none;
}

.mv_nav li {
  margin-bottom: 5px;
}

.mv_nav li:last-child {
  margin-bottom: 0;
}

/* ===== btn ===== */
.cmn-btn.type01 {
  display: table;
  color: #000;
  width: 100%;
  height: 55px;
  position: relative;
  font-size: 1.5rem;
  background: #f2f6fa;
  border: 1px solid #dee4e8;
  border-radius: 4px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 38px;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 1.3;
  transition: 0.3s;
}

.cmn-btn.type01 span {
  display: table-cell;
  vertical-align: middle;
}

.cmn-btn.type01:before, .cmn-btn.type01:after {
  content: "";
  position: absolute;
}

.cmn-btn.type01:before {
  width: 24px;
  height: 100%;
  background: #4488c7;
  top: 0;
  right: 0;
}

.cmn-btn.type01:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 7px;
  border-color: transparent transparent transparent #fff;
  right: 8px;
  top: 50%;
  margin-top: -4px;
}

/*!────────────────────────────────────────
 * _about_category.scss
 * ──────────────────────────────────────── */
/* about/index.html */
@media screen and (max-width: 480px) {
  body#about.index #main .index:last-of-type .left {
    width: 100%;
  }
}

/* about/organization/index.html */
@media screen and (max-width: 480px) {
  body#about.organization #tabs ul.tab3 li {
    width: 33.33333%;
  }
}

table.style01 th.lv03 {
  width: 31%;
}

/* about/history/timeline.html */
@media screen and (max-width: 480px) {
  body#about.history_timeline table,
  body#about.history_timeline th,
  body#about.history_timeline td {
    display: block;
    width: 100%;
  }
  body#about.history_timeline table.style01 {
    border: none;
    padding: 0;
  }
  body#about.history_timeline table.style01 th.lv03 {
    display: block;
    width: 100%;
    margin: 0;
  }
  body#about.history_timeline table.style01 th {
    margin-bottom: 0;
  }
  body#about.history_timeline table.style01 td {
    margin-top: 0;
    position: relative;
  }
  body#about.history_timeline table.style01 td::after {
    content: ">";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    bottom: -20px;
    margin: auto;
    width: 1em;
    height: 1em;
    transform: rotate(90deg);
  }
  body#about.history_timeline table.style01 tr:last-child td::after {
    content: none;
  }
  #about.philosophy .left {
    float: none;
    display: block;
    margin: auto;
  }
}

/*!────────────────────────────────────────
 * _press_release_policy_category.scss
 * ──────────────────────────────────────── */
#press_release-policy #main p.right {
  float: none;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #press_release-policy table {
    table-layout: fixed;
    width: 100%;
  }
  #press_release-policy table td {
    word-wrap: break-word;
  }
}

/*!────────────────────────────────────────
 * _access_category.scss
 * ──────────────────────────────────────── */
@media screen and (max-width: 767px) {
  #access #tabs ul.tab5 {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 10px;
  }
}

@media screen and (max-width: 375px) {
  #access #tabs ul.tab5,
  #access table#parking_price {
    font-size: 2.66667vw;
  }
  #access .parking_price_wrp {
    margin-left: 0;
    margin-right: 0;
  }
  #access table#parking_price {
    width: 100%;
  }
  #access table#parking_price td {
    padding: 5px 0;
  }
}

/*!────────────────────────────────────────
 * _inquiry_category.scss
 * ──────────────────────────────────────── */
@media screen and (max-width: 767px) {
  /* テーブル縦積み */
  #inquiry table {
    border: 0;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  #inquiry table th, #inquiry table td {
    display: block;
    margin: 0;
    border: 0;
    border-top: 1px solid #ccc;
  }
  /* フォーム整形 */
  #inquiry input[name="mail"], #inquiry input[name="address"], #inquiry input[name="tel"], #inquiry textarea {
    width: 100%;
  }
}

/*!────────────────────────────────────────
 * _press_release_category.scss
 * ──────────────────────────────────────── */
@media screen and (max-width: 480px) {
  /* table幅調整 */
  #press_release table {
    width: 100% !important;
  }
  #press_release table th {
    width: 67px;
  }
  #press_release.index #main .index {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  #press_release.index #main .index .left,
  #press_release.index #main .index .right {
    float: none;
  }
  #press_release.index #main .index p {
    height: 100%;
  }
  #press_release.index #main .index p a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
}
