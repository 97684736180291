/*!────────────────────────────────────────
 * _mobile_side.scss
 * ──────────────────────────────────────── */
#side {
  width: 710/750 * 100%;
  margin: 20px auto;
  float: none;
}
//サイドバナー JavaScript連動
#banner {
  font-size: 1.5rem;
  li.mobile {
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid #4488c7;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ccc;
    margin-bottom: 10px;
    a {
      display: block;
      padding: 1em 0.75em;
      color: #4488c7;
      @include circleArrow();
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .left,
  .right {
    float: none;
  }
}
//サイド subnavi JavaScript連動
#subnavi {
  background: transparent;
  font-size: 1.5rem;
  a {
    color: #4488c7;
  }
  > div {
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ccc;
    background: transparent;
    overflow: hidden;
    ul {
      background: transparent;
      padding: 0;
      ul {
        background-color: transparent;
      }
    }
  }
  p.mobile {
    padding: 0;
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid #4488c7;
    margin-bottom: 10px;
    color: #4488c7;
    padding: 1em 0 1em 0.75em;
    a {
      display: block;
      @include circleArrow();
    }
  }
  li.mobile {
    border-bottom: 1px solid #ddd;
    > a {
      display: block;
      font-size: 1.4/1.5 * 100%;
      padding: 1em 0.75em;
      color: #1c3e6e;
      @include circleArrow($bgColor: #1c3e6e);
    }
    > ul {
      border-top: 1px solid #ddd;
      li {
        font-size: 1.3/1.5 * 100%;
        border-bottom: 1px solid #ddd;
        a {
          display: block;
          background-image: none;
          background-color: #fff;
          padding: 0.7em 0.5em 0.7em 3em;
          @include circleArrow();
          &.on {
            background-image: none;
            background-color: #ddd;
          }
        }
      }
    }
  }
}
//フォントサイズ指定
@media screen and (max-width: 375px) {
  #banner {
    font-size: 30/750 * 100vw;
  }
  #subnavi {
    font-size: 30/750 * 100vw;
  }
}
