/*!────────────────────────────────────────
 * _mobile_header.scss
 * ──────────────────────────────────────── */
/*------------------------------------------
  header
------------------------------------------*/
#header_area,
#gnavi {
  // display: none;
  width: 0;
  height: 0;
  opacity: 0;
  font-size: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
}

#mobile_header {
  display: block;
}
.site-header {
  letter-spacing: 0.08em;
  position: relative;
}

.header-contents {
  background: #fff;
  padding: 16px 20px;
  align-items: center;
}

.groval-nav {
  background: #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  ul,
  ol {
    list-style: none;
  }
}

.groval-nav-fix {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.gnav-list {
  text-align: center;
  max-width: 1600px;
  margin: auto;
  img {
    display: none;
  }
}

.gnav-item {
  width: 20%;
  position: relative;

  &:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #d0d5d9;
    position: absolute;
    top: 0;
    right: 1px;
  }

  &:last-child {
    &:before {
      content: none;
    }

    .gnav-link {
      border-right: none;
    }
  }

  &.active {
    background: #d3e8c1;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 11px 12px 11px;
      border-color: transparent transparent #3c5a8c transparent;
      position: absolute;
      bottom: -2px;
      left: 50%;
      margin-left: -11px;
      z-index: 3;
    }
  }
}

.gnav-link {
  font-size: 36 / 750 * 100vw;
  display: block;
  border-right: 1px solid #fff;
  padding: 10px 0;
  font-weight: 600;
}

.gnav-dropdown {
  position: absolute;
  width: 100%;
}

.dropdown {
  display: none;
  position: absolute;
  width: 100%;
  border-top: 2px solid #d0d5d9;
  background: #b7d049;
  z-index: 2;

  ul {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    justify-content: flex-start;
  }

  li {
    width: 33.333%;
    margin-top: 10px;
    padding: 0 5px;
    box-sizing: border-box;
    display: grid;
    display: -ms-grid;
    -ms-grid-columns: 100% 100% 100%;
    -ms-grid-rows: 100% 100% 100%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  a {
    display: block;
    padding: 1em;
    border: 1px solid #fff;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    padding-right: 26px;
    transition: 0.3s;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: 22px;
      background: #fff;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 8px;
      border-color: transparent transparent transparent #1e3c6e;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      right: 7px;
    }

    &:hover {
      color: #00b496;
    }
  }
}

.gnav-list-sp01 {
  padding-bottom: 0;

  ul {
    justify-content: space-between;
    padding: 0 0 1.5rem 0;
    margin: 0 0 0 0;
    list-style: none;
  }

  li {
    width: 49%;
    margin-bottom: 2%;
  }

  .gnav-item {
    width: 100%;
    margin-bottom: 2%;
  }

  a {
    display: block;
    background: #fff;
    font-size: 30 / 750 * 100vw;
    padding: 1em;
    border: 1px solid #fff;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    padding-right: 26px;
    font-weight: 600;

    &:before {
      content: "";
      width: 22px;
      background: #fff;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 8px;
      border-color: transparent transparent transparent #1e3c6e;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      right: 7px;
    }
  }
  img {
    display: none;
  }
}

.gnav-list-sp02 {
  padding-bottom: 10px;

  ul {
    padding: 0 0 15px 0;
    margin: 0 0 0 0;
  }

  li {
    width: 100%;
    margin-bottom: 2%;
  }

  a {
    display: block;
    background: #bbbbbb;
    font-size: 30 / 750 * 100vw;
    padding: 1em;
    border: 1px solid #bbbbbb;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    text-align: center;
    color: #fff !important;
    font-weight: 600;
  }
  img {
    display: none;
  }
}
//モーダルメニュー内レイアウト
.site-header {
  padding-bottom: 10px;
}

.header-contents {
  padding: 10px;
  height: 60px;
}

.sitelogo {
  width: 150/375 * 100vw;
  // width: calc(100% - 60px - 10px);
}

.header-menu {
  display: none;
}

.gnav-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 60/375 * 100vw;
  max-width: 60px;
  height: 60/375 * 100vw;
  max-height: 60px;
  top: 0;
  right: 0;
  background: #4488c7;
  z-index: 30;
  position: fixed;
  z-index: 3;

  div {
    width: 30/375 * 100vw;
    max-width: 30px;
    height: 26/375 * 100vw;
    max-height: 26px;
    position: absolute;
    top: 17/60 * 100%;
    left: 15/60 * 100%;
  }

  span {
    transition: 0.2s;
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    left: 0;

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 50%;
      margin-top: -1px;
    }

    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}

.groval-nav {
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto !important; //JavaScriptのinlineスタイル回避
  z-index: 25;
  background: rgba(236, 236, 236, 1);
  border-bottom: none;
  padding: 10px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

.gnav-logo {
  margin: 0 0 10px 0;
  // width: calc(100% - 60px - 10px);
  width: 150/375 * 100vw;
  height: 40px;
  a {
    img {
      margin: 11px 0;
    }
  }
}

.gnav-close {
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  color: #fff;
  padding: 15px 0;
  font-size: 32 / 750 * 100vw;
  background: #4488c7 url(../images/common/ico-close.png) no-repeat left 20px center;
  background-size: 23px;
}

.gnav-list {
  padding: 15px 0;
}

.gnav-item {
  width: 100%;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  &:before,
  &.active:after {
    content: none;
  }
}

.gnav-link {
  text-align: left;
  border: 1px solid #fff !important;
  background: #fff;
  color: #4488c7;
  border-radius: 4px;
  font-size: 30 / 750 * 100vw;
  padding-left: 20px;
  position: relative;

  &:before {
    content: "";
    width: 22px;
    background: #fff;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 8px;
    border-color: transparent transparent transparent #1e3c6e;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    right: 7px;
  }
}

.gnav-dropdown {
  display: none;
}
a:link {
  color: #1e3c6e;
  text-decoration: none;
}
h1 {
  margin: 6px 0 6px 0;
}
//タブレットオンリー設定
@media only screen and (min-width: 768px) and (max-width: 953px) {
  .sitelogo {
    width: calc(100% - 60px - 10px);
  }

  .header-menu {
    width: 70%;

    .menu-list {
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 6px;
    }

    .guide-list {
      width: 100%;
      justify-content: flex-end;
    }
  }

  .gnav-link {
    font-size: 28 / 750 * 100vw;
  }

  .dropdown {
    font-size: 26 / 750 * 100vw;
  }
}

//スマートフォンオンリー設定
@media only screen and (max-width: 460px) {
  .sitelogo,
  .gnav-logo {
    width: 60vw;
  }
}
//ヘッダーメニュー内フォントサイズ最大値設定
@media only screen and (min-width: 376px) and (max-width: 953px) {
  .gnav-link {
    font-size: 1.5rem !important;
  }
  .gnav-list-sp01 a {
    font-size: 1.5rem !important;
  }
  .gnav-list-sp02 a {
    font-size: 1.5rem !important;
  }
  .gnav-close {
    font-size: 1.6rem !important;
  }
}
