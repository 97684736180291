/*!────────────────────────────────────────
 * mobile.scss
 * for [がん研究会](https://www.jfcr.or.jp/)
 * 日本語用
 * ──────────────────────────────────────── */

@import "./components/mixin";

@import "./components/common_settings";

@import "./components/mobile_header";

@import "./components/mobile_footer";

@import "./components/mobile_side";

@import "./components/top_page";
@import "./components/top_mobile_gnav";

@import "./components/about_category";

@import "./components/press_release_policy_category";

@import "./components/access_category";

@import "./components/inquiry_category";

@import "./components/press_release_category";
