/*!────────────────────────────────────────
 * _press_release_category.scss
 * ──────────────────────────────────────── */

@media screen and (max-width: 480px) {
  /* table幅調整 */
  #press_release table {
    width: 100% !important;
    th {
      width: 67px;
    }
  }
  //index.htmlのindexの高さ揃え
  #press_release.index {
    #main {
      .index {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .left,
        .right {
          float: none;
        }
        p {
          height: 100%;
          a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
          }
        }
      }
    }
  }
}
