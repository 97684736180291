/*!────────────────────────────────────────
 * _mobile_footer.scss
 * ──────────────────────────────────────── */
/*------------------------------------------
  footer
------------------------------------------*/

@media only screen and (min-width: 376px) and (max-width: 953px) {
  .cmn-btn.type01 {
    font-size: 1.4rem !important;
  }
  .footer-about .about-title {
    font-size: 1.7rem !important;
  }
  .footer-about .about-address {
    font-size: 1.3rem !important;
  }
  .footer-copyright {
    font-size: 1rem !important;
  }
  .footer-link .link-list {
    font-size: 1.3rem !important;
  }
}

@media only screen and (max-width: 953px) {
  #footer_area {
    display: none;
  }

  #mobile_footer {
    display: block;
    background-color: white;
    background-image: none;
    img {
      display: none;
    }
    ol,
    ul {
      list-style: none;
    }
  }

  .site-footer {
    padding-bottom: 0px;
  }

  .footer-facility .facility-list {
    width: 100%;
    display: block;
  }

  .footer-top {
    padding: 10px 5px 6px;
  }

  .footer-top .cmn-inner {
    padding: 0;
  }

  .footer-facility {
    padding-top: 10px;
  }

  .footer-facility .facility-list {
    width: 100%;
    display: block;
    ul {
      margin: 0;
    }
  }

  .footer-facility .facility-list li {
    width: 50%;
    padding: 0 2px;
    margin-bottom: 4px;
    box-sizing: border-box;
    &.thisSite {
      display: none;
    }
  }

  .footer-facility .facility-list .flexbox100 {
    width: 100%;
  }

  .cmn-btn.type01:before {
    width: 24px;
    height: 100%;
    background: #4488c7;
    top: 0;
    right: 0;
  }

  .cmn-btn.type01 {
    display: table;
    width: 100%;
    height: 55px;
    position: relative;
    font-size: 28 / 750 * 100vw;
    background: #f2f6fa;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-weight: 600;
    padding-left: 15px;
    padding-right: 38px;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 1.3;
    transition: 0.3s;
  }

  .cmn-btn.type01:before,
  .cmn-btn.type01:after {
    content: "";
    position: absolute;
  }

  .cmn-btn.type01 span {
    display: table-cell;
    vertical-align: middle;
  }

  .cmn-btn.type01:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 7px;
    border-color: transparent transparent transparent #fff;
    right: 8px;
    top: 50%;
    margin-top: -4px;
  }

  .footer-bottom {
    padding: 0;
    background: #ffffff;
    color: #656565;
  }

  .footer-bottom .cmn-inner {
    padding: 0;
  }

  .footer-bottom a {
    color: #656565;
    background: #eeeeee;
  }

  .cmn-inner.type01 {
    max-width: 1200px;
  }

  .cmn-inner {
    margin: auto;
  }

  .footer-about {
    padding: 25px 0;
    text-align: center;
    border-bottom: none;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    width: 100%;
    margin-bottom: 0;
    align-items: center;
    justify-content: flex-start;
    background: #eeeeee;
  }

  .footer-about .about-title {
    font-weight: bold;
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 34 / 750 * 100vw;
  }

  .footer-about .about-address {
    margin: 0;
    padding-left: 0;
    font-size: 26 / 750 * 100vw;
    width: 100%;
    font-weight: 600;
  }

  .footer-link {
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    width: 100%;
    align-items: center;
    .copyright {
      display: none;
    }
  }

  .footer-link .link-list li {
    width: 50%;
    margin-right: 0;
    display: table;
    height: 65px;
    text-align: center;
    border-bottom: 1px solid #ffffff;
  }

  .footer-link .link-list li:nth-child(odd) a {
    border-right: 1px solid #ffffff;
  }

  .footer-link .link-list li:nth-child(even) a {
    border-left: 1px solid #ffffff;
  }

  .footer-link .link-list a {
    display: table-cell;
    vertical-align: middle;
    padding: 0;
    border-bottom: 1px solid #ffffff;
  }

  .footer-link .link-list a:before {
    content: none;
  }

  .footer-copyright {
    text-align: center;
    width: 100%;
    font-size: 20 / 750 * 100vw;
    font-weight: 600;
    -webkit-order: 3;
    -ms-order: 3;
    order: 3;
    margin: 0;
    background: #eeeeee;
    padding: 0 0 25px;
  }

  .footer-link .link-list {
    width: 100%;
    font-size: 26 / 750 * 100vw;
    margin: 0;
    font-weight: 600;
  }

  .btn-pagetop {
    bottom: 20px;
    position: fixed;
    right: 0;
    z-index: 20;
  }

  .btn-pagetop a {
    width: 40px;
    height: 40px;
    display: block;
    background: #4488c7;
  }

  .btn-pagetop a:after {
    content: "";
    display: block;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -4px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    margin-left: -5px;
  }
}
