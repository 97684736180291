/*!────────────────────────────────────────
 * _inquiry_category.scss
 * ──────────────────────────────────────── */

@media screen and (max-width: 767px) {
  /* テーブル縦積み */
  #inquiry table {
    border: 0;
    border-left: 1px solid#ccc;
    border-right: 1px solid#ccc;
    border-bottom: 1px solid#ccc;
    th,td{
      display: block;
      margin: 0;
      border: 0;
      border-top: 1px solid#ccc;
    }
  }
  /* フォーム整形 */
  #inquiry{
    input[name="mail"],input[name="address"],input[name="tel"],textarea{
      width: 100%;
    }
  }
}