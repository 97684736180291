/*!────────────────────────────────────────
 * _common_settings.scss
 * ──────────────────────────────────────── */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap");
html {
  font-size: 62.5%;
}
body {
  font-size: 10/12 * 100% !important; // 1remを10pxに固定
  border-top: none;
  font-family: "Noto Sans Japanese", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W6", "メイリオ", "Meiryo", "MS PGothic", sans-serif;
  &.index {
    margin: 0;
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}
#bread {
  > p {
    display: none;
  }
}
#contents {
  width: 100%;
}
#main_area {
  width: 100%;
  float: none;
}
#main {
  width: 710/750 * 100%;
  margin: 0 auto;
}
.totop {
  display: none;
}
#dataTable {
  a {
    img {
      display: none;
    }
  }
}
.wh01 th {
  width: 21%;
}
@media screen and (max-width: 767px) {
  figure.right {
    margin: 12px auto !important;
    text-align: center;
    float: none;
  }
}
/*------------------------------------------
  parts
------------------------------------------*/
/* ===== flexbox ===== */

.flexbox {
  display: flex;
  flex-wrap: wrap;
}

/* p.title */ //JavaScript連動
p.title,
#main_area h1,
#main p.lead {
  img {
    visibility: hidden;
    opacity: 0;
  }
}
p.title.mobile {
  font-size: 1.6rem;
  font-weight: bold;
  color: #4488c7;
  line-height: 1.6;
  border-top: 1px solid #4488c7;
  background-color: #fff;
  padding: 0.3em 0.5em;
  box-shadow: 0px 1px 3px #ccc;
  &.oshirase {
    a {
      display: block;
      color: #4488c7;
      @include circleArrow($right: 0px);
    }
  }
}
/* p.lead */ //JavaScript連動
#main p.lead.mobile {
  font-size: 1.6rem;
  font-family: "Noto Serif JP", serif;
}

/* h1.mobile & outline */ //JavaScript連動
#main_area {
  h1.mobile {
    line-height: 1.6;
    font-size: 1.6rem;
    border-left: 3px solid #4488c7;
    padding: 0.5em 0.3em;
    width: 710/750 * 100%;
    margin: 10px auto;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 0px 3px #ccc;
  }
  h2 {
    font-size: 1.4rem;
    margin: 20px auto;
  }
}

/* 年号リスト */
p.page_link {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

/* Top 休部中を最後尾に */
body#top {
  #main {
    .container {
      #dept {
        #dep14 {
          order: 13;
        }
        #dep13 {
          order: 14;
        }
      }
    }
  }
}

#link01 + p {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@media screen and (max-width: 480px) {
  img.right {
    margin: 0 auto 20px !important;
    text-align: center;
    float: none;
    display: block;
  }
  #main {
    ul.photo {
      float: none;
      li {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        float: none;
        margin-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  /* オリジナルHPへ改行 */
  .icon_img.small {
    display: block;
    margin-top: 1em;
  }
  /* researcherコンテンツ幅100% */
  .res_left {
    float: none;
    width: 100%;
  }
  /* SPのみ左寄せ */
  #main p.t_right {
    text-align: left;
  }
  #main .index p a {
    width: 100%;
    min-height: 68px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
