/*!────────────────────────────────────────
 * _about_category.scss
 * ──────────────────────────────────────── */
/* about/index.html */
@media screen and (max-width: 480px) {
  body#about.index {
    #main {
      .index:last-of-type {
        .left {
          width: 100%;
        }
      }
    }
  }
}

/* about/organization/index.html */
@media screen and (max-width: 480px) {
  body#about.organization {
    #tabs ul.tab3 li {
      width: 33.33333%;
    }
  }
}

table.style01 th.lv03 {
  width: 31%;
}
/* about/history/timeline.html */
@media screen and (max-width: 480px) {
  body#about.history_timeline {
    table,
    th,
    td {
      display: block;
      width: 100%;
      // margin: 0;
    }
    table.style01 {
      border: none;
      padding: 0;
      th.lv03 {
        display: block;
        width: 100%;
        margin: 0;
      }
      th {
        margin-bottom: 0;
      }
      td {
        margin-top: 0;
        position: relative;
        &::after {
          content: ">";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: auto;
          bottom: -20px;
          margin: auto;
          width: 1em;
          height: 1em;
          transform: rotate(90deg);
        }
      }
      tr:last-child {
        td {
          &::after {
            content: none;
          }
        }
      }
    } //table.style01
  }
  #about.philosophy .left{
    float: none;
    display: block;
    margin: auto;
  }
}
