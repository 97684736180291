//────────────────────────────────────────
// _mixin.scss
//────────────────────────────────────────

//map
$breakpoint: (
  sp: "screen and (max-width: 480px)",
  tb: "screen and (max-width: 767px)",
  // wrp: "screen and (max-width: 953px)",
);

//breakpoint
@mixin mq($bp) {
  @media #{map-get($breakpoint, $bp)} {
    @content;
  }
}

//丸背景矢印mixin
@mixin circleArrow($bgColor: #4488c7, $right: 15px) {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: auto;
    right: $right;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    overflow: hidden;
    background: $bgColor;
  }
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 0 3px 5px;
    border-color: transparent transparent transparent #fff;
    right: $right + 4px;
    top: 50%;
    transform: translateY(-50%);
  }
}
