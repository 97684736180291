/*!────────────────────────────────────────
 * _press_release_policy_category.scss
 * ──────────────────────────────────────── */

 #press_release-policy #main p.right{
   float: none;
   text-align: right;
 }

 @media screen and (max-width: 767px) {
  #press_release-policy table{
    table-layout: fixed;
    width: 100%;
    td{
      word-wrap: break-word;
    }
  }
}